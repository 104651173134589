<template>
<div>
  <div class="row container justify-content-center">

    <div class="card text-center col-sm-12 col-lg-3 BWS"> <!-- style="width: 22rem;" -->
      <h5 class="card-title">Bluewing</h5>
      <img src="../assets/images/Server/BlueWing2.png" class="card-img-top" alt="Logo Bluewing Server">
      <div class="card-body">
        <p class="card-text CTS B-F"> <!-- B-F -->
          | Shadsoft Server    | <br/> shadsoft.fr:6121 <br/><br/>
          | Darkwire Server I  | <br/> server1.dark-wire.com:6121 <br/><br/>
          | Darkwire Server II | <br/> server2.dark-wire.com:6122
        </p>
        
        <p class="card-text CTS B-B"> <!-- B-B -->
          {{INF1}}
        </p>
        <p class="card-text CTS B-B"> <!-- B-B -->
          {{INF2}}
        </p>
        <p class="card-text CTS B-B"> <!-- B-B -->
          {{INF3}}
        </p>
      </div>
      <i class="far fa-question-circle B-F"></i> <!-- B-F -->
      <a href="https://dark-wire.com/products.php" target="_blank" class="btn btn-primary">details...</a>
    </div>

    <div class="card text-center col-sm-12 col-lg-3 SUP">
      <h5 class="card-title">{{NeedSupport}}</h5>
      <i class="fas fa-tools S-F"></i> <!-- S-F -->
      
    <!-- <img src="../assets/images/Support/Support.png" class="card-img-top" alt="Logo Support"> -->
      <div class="card-body">
        <p class="card-text CTS S-B"> <!-- S-B -->
          {{Option1}}<br/><br/>
          {{Option2}}<br/><br/>
          {{Option3}}<br/><br/>
          {{Option4}}<br/><br/>
          {{Option5}}
        </p>
      </div>
      <i class="far fa-question-circle S-F"></i> <!-- S-F -->
      <a class="btn btn-primary" :href="Support()" :title="Contact"><i class="far fa-envelope"></i> {{Contact}} </a>
    </div>

    <div class="card text-center col-sm-12 col-lg-3 WS">
      <h5 class="card-title">{{NeedWebSite}}</h5>
      <img src="../assets/images/WebSite/web-development-icon-svg.png" class="card-img-top W-F" alt="Logo WebSite">
      <div class="card-body">
        
        <p class="card-text CTS W-B">
          {{WebSiteNew}}<br/><br/>
          {{WebSiteUpdate}}<br/><br/>
          {{WebSiteResponsive}}<br/>
        </p>

      </div>
      <i class="far fa-question-circle W-F"></i>
      <a class="btn btn-primary" :href="WebSite()" :title="Contact"><i class="far fa-envelope"></i> {{Contact}} </a>
    </div>

  </div>
  
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Service',

  computed:{
    ...mapGetters([
      // choix de langue
      'Contact',
      'language',

      // BlueWing
      'INF1',
      'INF2',
      'INF3',

      // Support
      'NeedSupport',

      // Options
      'Option1',
      'Option2',
      'Option3',
      'Option4',
      'Option5',

      // Site Web
      'NeedWebSite',
      'WebSiteDetail',
      'WebSiteNew',
      'WebSiteUpdate',
      'WebSiteResponsive'
    ]),
  },

  methods:{

    Support(){
      if(this.language == "french"){
        let mailto = "mailto:jgshadsoft@gmail.com?subject=[SUPPORT] Demande de Support / Dépannage / Optimisations / Conseils / Information"
        return mailto

      } else {
        let mailto = "mailto:jgshadsoft@gmail.com?subject=[SUPPORT] Request Support / Troubleshooting / Optimizations / Tips / Information"
        return mailto
      }
    },

    WebSite(){
      if(this.language == "french"){
        let mailto = "mailto:jgshadsoft@gmail.com?subject=[WEB] Nouveau Projet Web / Visibilité et Référencement / Adaptation Mobile / Autres"
        return mailto

      } else {
        let mailto = "mailto:jgshadsoft@gmail.com?subject=[WEB] New Web Project / Visibility and SEO / Mobile Adaptation / Others"
        return mailto
      }
    }
  }
}
</script>

<style scoped lang="scss">
$white:#a4cbe0;
$blue:#14c6d7;

.container{
  padding: 0;
  margin : 0;
  // border: 1px solid red;
}
.CTS{
  // font-size: 0.7em;
}

.card{
  background-color: #417c95;
  // padding: 0 0.5em;
  margin : 0.5em 0.2em;
  width: 22em;
  perspective: 1000px;

  &-inner {
    width: 22em;
    transform-style: preserve-3d;
    transition: transform 0.5s;

    &:hover{
      transform: rotateY(180deg);
    }
  }

  &-face{
    width: 22em;
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border: 1px solid #ccc;

    &.front{
      transform: rotateY(0deg);
    }
    &.back{
      transform: rotateY(180deg);
    }
  }

  a {
    opacity: 0;
  }

  &:hover{
    a {
      opacity: 1;
    }
  }

  .fas, img {
    margin-top : 0.2em;
    color: black;
    font-size: 10em;
  }

  
}

.B-B, .S-B, .W-B  {
  display: none;
}

.BWS {
  &:hover{
    .B-B{
      display: inline-block;
    }
    .B-F{
      display: none;
    }
  }
}

.SUP {
  &:hover{
    .S-B{
      display: inline-block;
    }
    .S-F{
      display: none;
    }
  }
}

.WS {
  &:hover{
    .W-B{
      display: inline-block;
    }
    .W-F{
      display: none;
    }
  }
}

table {
  margin: 0.5em 0;
  padding: 0.7em 0;

  color: $blue;

  box-shadow: 3px 3px 9px 0px rgba(255,255,255,0.51);
  -webkit-box-shadow: 3px 3px 9px 0px rgba(255,255,255,0.51);
  -moz-box-shadow: 3px 3px 9px 0px rgba(255,255,255,0.51);
  -o-box-shadow: 3px 3px 9px 0px rgba(255,255,255,0.51);

  border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -o-border-radius: 4px 4px 4px 4px;

  tr{
    img{
      padding: 0 0.1em;
    }
  }

  td {
    padding: 0.1em 0.2em;
    border: 1px solid #333;
  }
  

  tfoot{
    text-align: right;
  }

  th{
    color: $white;
    font-style: italic;

    a{
      padding: 0 0.3em;
    }
  }

  thead{
    th{
      background-color: #4279a5;
      background: linear-gradient(0deg,#4279a5 0%, #63aec6 100%);
      background: -webkit-linear-gradient(0deg,#4279a5 0%, #63aec6 100%);
      background: -moz-linear-gradient(0deg,#4279a5 0%, #63aec6 100%);
      background: -o-linear-gradient(0deg,#4279a5 0%, #63aec6 100%);
    }
  }
}
p{
  color: $blue;
  text-align: center;

  @media (max-width:568px) and (orientation: landscape){
    font-size: 0.85em;
  }

  @media (max-width:375px) and (orientation: portrait) {
    font-size: 0.85em;
  }

}

a.ct{
  margin: 0.2em 0;
  text-align: right;
}

</style>